import React from "react"
import { motion } from "framer-motion"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

const variants = {
  hidden: { opacity: 0, x: -200, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: 0, y: -100 },
}

const NotFound = () => (
  <Layout>
    <SEO title="404" description="Strona nie istnieje" />
    <section className="not-found">
      <motion.div
        initial="hidden"
        animate="enter"
        exit="exit"
        variants={variants}
        transition={{ type: "linear" }}
        className="wrapper"
      >
        <div className="not-found-info">
          <h2 className="not-found-header">404</h2>
          <p className="not-found-desc">
            Strona o podanym adresie nie istnieje.
          </p>
          <Link to="/" className="not-found-link">
            Wróć na stronę główną
          </Link>
        </div>
      </motion.div>
    </section>
  </Layout>
)

export default NotFound
